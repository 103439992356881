<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的试卷</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">试卷详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <div style="margin-left: -4px" class="flexcb searchbox">
              <h3>试卷基本信息</h3>
            </div>

            <div class="flexdc" style="align-items: flex-start">
              <el-form
                :inline="true"
                :rules="rules"
                :model="ruleForm"
                ref="basicInfoForm"
              >
                <el-form-item
                  label="试卷名称："
                  style="margin-right: 20px"
                  prop="paperName"
                >
                  <p v-if="editBtn == true">{{ ruleForm.paperName }}</p>
                  <el-input v-model="ruleForm.paperName" v-else></el-input>
                </el-form-item>
                <el-form-item label="试卷总分：" style="margin-right: 20px">
                  <p>{{ ruleForm.paperTotalScore }}</p>
                </el-form-item>
                <el-form-item
                  label="通过分数："
                  style="margin-right: 20px"
                  prop="paperScore"
                >
                  <p v-if="editBtn == true">{{ ruleForm.paperScore }}</p>
                  <el-input-number
                    v-else
                    class="el-input-number"
                    :min="1"
                    placeholder="请输入试题分数"
                    v-model="ruleForm.paperScore"
                  ></el-input-number>
                </el-form-item>
                <!-- </el-form>
              <el-form :inline="true" :rules="rules" :model="ruleForm" ref="basicInfoForm"> -->
                <el-form-item v-if="editBtn">
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doEdit"
                    >编辑</el-button
                  >
                </el-form-item>
                <el-form-item v-else>
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doSave"
                    >保存</el-button
                  >
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doGive"
                    >取消</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div
              class="bottom searchbox"
              style="padding-top: 0; padding-right: 1rem"
            >
              <h3>试卷题目信息</h3>
              <div class="flexcb" style="align-items: flex-start">
                <el-form :inline="true">
                  <el-form-item label="题目内容:" style="margin-right: 20px">
                    <el-input v-model="topiccontent" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="题目类型:" style="margin-right: 20px">
                    <el-select
                      v-model="TopictypeVal"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in Topictype"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      style="padding: 8px 20px"
                      class="bgc-bv"
                      @click="chaxun"
                      >查询</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="ovy-a flex1" id="list-box">
                  <div
                    class="list"
                    v-for="(item, index) in tableData"
                    :key="index"
                    v-show="tableData.length > 0"
                  >
                    <div class="list-item df">
                      <span
                        class="tixing"
                        :class="{
                          tixing1: item.questionType == 10,
                          tixing2: item.questionType == 20,
                          tixing3: item.questionType == 30,
                          tixing4: item.questionType == 40,
                        }"
                        >{{
                          item.questionType == "10"
                            ? "单选题"
                            : item.questionType == "20"
                            ? "多选题"
                            : item.questionType == "30"
                            ? "判断题"
                            : "填空题"
                        }}</span
                      >
                      <div class="subject flex1">
                        <div class="subject-title">
                          <span
                            v-html="$xss(item.questionTitle)"
                            style="display: flex"
                            >{{ item.questionTitle }}</span
                          >
                        </div>
                        <div class="option" v-if="item.questionType == 10">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image>
                          </span>
                        </div>
                        <div class="option" v-if="item.questionType == 20">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image
                          ></span>
                        </div>
                        <div class="analysis">
                          正确答案：<span
                            v-show="item.optionList.length != 0"
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{
                              items.optionIsCorrect ? letterArr[indexs] : ""
                            }}</span
                          >
                          <span v-show="item.optionList.length == 0">{{
                            item.questionAnswer
                          }}</span>
                        </div>
                        <div style="display: flex">
                          <p>答案解析：</p>
                          <span
                            class="analysis img-boxs"
                            style="flex: 1"
                            v-html="$xss(item.questionAnalysis)"
                            >{{ item.questionAnalysis }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "testPaperEdit",
  components: {
    // Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      stu: "add",
      editBtn: true, //

      parperId: "",
      ruleForm: {
        paperTotalScore: "",
        paperName: "",
        paperScore: "",
        salesState: "",
        Trainingtype: "",
      },
      Topictype: [
        {
          value: "10",
          label: "单选题",
        },
        {
          value: "20",
          label: "多选题",
        },
        {
          value: "30",
          label: "判断题",
        },
        {
          value: "40",
          label: "填空题"
        }
      ],
      TopictypeVal: "",
      topiccontent: "",
      rules: {
        paperTotalScore: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        paperName: [{ required: true, message: "请输入", trigger: "blur" }],
        paperScore: [{ required: true, message: "请输入", trigger: "blur" }],
      },
       //答案转换
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    init() {
      const query = this.$route.query;
      console.log(query);
      this.paperId = query.paperId;
      this.paperOrgin = query.paperOrgin;
      this.stu = query.stu;
      if (this.paperId) {
        this.getEdit(this.paperId);
        this.getData(-1);
      }
    },

    optionFM(msg) {
      if (msg == 0) {
        return "选项A";
      } else if (msg == 1) {
        return "选项B";
      } else if (msg == 2) {
        return "选项C";
      } else if (msg == 3) {
        return "选项D";
      } else if (msg == 4) {
        return "选项E";
      } else if (msg == 5) {
        return "选项F";
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.paperId,
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.topiccontent) {
        params.questionName = this.topiccontent;
      }
      this.doFetch({
        url: "/evaluate/paper/question/pageList",
        params,
        pageNum,
      });
      //   this.getEdit(this.parperId);
    },
    chaxun() {
      this.getData(-1);
    },
    getEdit(paperId) {
      this.$post("/evaluate/paper/getInfo", {
        paperId,
      }).then((ret) => {
        this.ruleForm = { ...this.ruleForm, ...ret.data };
      });
    },
    //编辑
    doEdit() {
      this.editBtn = false;
    },
    //保存
    doSave() {
      const that = this;
      const basicInfoForm = this.$refs.basicInfoForm;
      let params = {
        paperTotalScore: that.ruleForm.paperTotalScore,
        paperName: that.ruleForm.paperName,
        paperScore: that.ruleForm.paperScore,
        paperId: that.paperId,
      };
      basicInfoForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.paperScore > this.ruleForm.paperTotalScore) {
            this.$message({
              message: "通过分数不能大于试卷总分数,请重新输入",
              type: "warning",
            });
          } else {
            that.$post("/evaluate/paper/modify", params).then((ret) => {
              if (ret.status == 0) {
                that.editBtn = true;
                that.getEdit(that.paperId);
                that.subShow = true;
              }
            });
          }
        }
      });
    },
    doGive() {
      this.getData(-1);
      this.editBtn = true;
    },
    // // 上下移
    // onSort(questionId, stu) {
    //   this.$post(
    //     stu == "top"
    //       ? "/biz/paper/moveUpQuestion"
    //       : "/biz/paper/moveDownQuestion",
    //     { questionId }
    //   )
    //     .then(ret => {
    //       if (ret.status == 0) {
    //         this.getData(-1);
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // // 试题删除
    // onDel(questionId, paperId) {
    //   const that = this;
    //   that
    //     .$confirm("你确定要从试卷中删除该试题吗", "删除", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning"
    //     })
    //     .then(() => {
    //       that
    //         .$post("/biz/paper/deleteQuestion", { questionId, paperId })
    //         .then(ret => {
    //           if (ret.status == 0) {
    //             that.subShow = true;
    //             that.getData(-1);
    //           }
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //       return;
    //     });
    // },
    // onEdit(questionId) {
    //   this.$refs.Testpaper2.showPopUp(questionId);
    // },
    // addquestion(parperId) {
    //   this.$refs.TestPaper.showPopUp(parperId);
    // },
    //提交审核
    subject(paperId) {
      const that = this;
      that
        .$confirm("您已修改试卷内容,是否立即提交审核", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that
            .$post("/biz/paper/modifyAuditState", {
              resourceId: paperId,
              auditState: "20",
            })
            .then((ret) => {
              if (ret.status == 0) {
                that.subShow = true;
                that.$message({
                  message: "提交成功",
                  type: "success",
                });
                setTimeout(() => {
                  that.$router.push({
                    name: "testPaperList",
                    params: {
                      refresh: true,
                      refName: "first",
                    },
                  });
                }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
        }
      }
    }
  }
}
.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.searchbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}
.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}
</style>
